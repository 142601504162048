@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300;400;500;600;700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
	--main-1: #283b70;
	--secondary-1: #dbe3fa;
	--secondary-2: #f5f5f5;
	--secondary-3: #e6e6e6;
	--secondary-4: #f7f8fb;
	--main-2: #fff;
	--text-1: #444;
	--text-2: #5d5d5d;
	--text-3: #6486e3;
  --font-family: "Hind Madurai", sans-serif;
  --second-family: "Exo", sans-serif;
}

* {
  font-family: var(--font-family);
}


/* общее */
.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
}
.content {
  flex: 1; /* Это разделит оставшееся пространство между верхним содержанием и нижним футером */
}

/* меню */
.menu {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
}

.menu-space {
  /*background-color: #F5F5F5;
  border-left: 3px solid #01487E;
  border-right: 3px solid #01487E;*/
  background: var(--secondary-1);
  display: block !important;
}
.menu-space .main_container {
  padding-top: 0;
  padding-bottom: 0;
}
.brand_toggle {
  display: flex;
}

.menu-space .btn {
  padding: 0;
}
.menu-accesibility {
  border-bottom: 1px solid var(--main-1);
}

.menu_eye {
  color: var(--main-1);
}

.accesibility-A, .accesibility-С {
  margin-right: 100px;
}

.accesibility-A button {
  width: 4rem;
  border: 'none';
  border-radius: 5px;
  color: #000;
  background: #D9D9D9;
  margin-right: 1rem
}

.accesibility-С button {
  font-size: 150%;
  border: none;
  border-radius: 5px;
  width: 4rem;
  margin-right: 1rem
}

.menu-rectangle {
  background: var(--secondary-1);
  display: flex;
  flex-direction: row;
  max-width: 100%;
}

.menu-rectangle a {
  text-decoration: none;
  color: inherit;
  display: block;
}

.justify-content-space-around {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.navbar,
.navbar .nav-link,
.navbar .navbar-brand {
  /*font-family: var(--font-family);
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  color: var(--text-1);*/
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  color: var(--text-1);
}


.navbar-toggler:focus {
  box-shadow: none !important;
}

.navbar .dropdown-item {
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 18px;
  color: var(--text-1);
  padding: 0;
  padding: 5px;
}

.navbar .dropdown-menu {
  background: var(--secondary-1); /*Цвет фона подменю по умолчанию*/
}

.navbar .dropdown-item:hover {
  color: #ffffff; /* Цвет текста при наведении */
  background-color: #01487E; /* Цвет фона при наведении */
}
.dropdown-toggle::after {
  display: none !important; 
}

/* тело */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.h1_text {
  padding: calc(2rem + .5vw) 0;
  color:#01487E;
  font-size: calc(1.375em + .9vw);
}

.h2_text {
  margin: calc(1.5rem + .5vw) 0 calc(2rem + .5vw) 0;
  color:#01487E;
  position: relative;
  font-size: calc(1.2em + .6vw);
}

.h2_text_first {
  margin-top: 0;
}

.h2_text::after {
  content: "";
  display: block;
  position: absolute;
  bottom: calc(-0.1rem - .3vw);
  left: 0;
  width: 70%;
  border-bottom: 1px solid black;
}

.h3_text {
  margin: calc(0.75rem + .5vw) 0 calc(1rem + .5vw) 0;
  color: var(--main-1);
  font-size: calc(1.2em + .3vw);
}

.h4_text {
  margin: calc(0.5rem + .5vw) 0 calc(1rem + .5vw) 0;
  color: var(--main-1);
  position: relative;
  font-size: calc(1.15em);
}

/*.h4_text::after {
  content: "";
  display: block;
  position: absolute;
  bottom: calc(-0.1rem - .3vw);
  left: 0;
  width: 60%;
  border-bottom: 1px solid black;
}*/

.h6_text {
  margin-bottom: calc(2rem + .5vw);
  font-weight: 400;
  font-size: calc(1.15em);
}

p {
  margin-bottom: 0.5rem;
}

.icon-header-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end
}



/* контрасты для слабовидящих */
.contrast-white-black,
.contrast-white-black :is(h1, h2, h3, h4, h5, h6, p, a, span, .main_container, .card-rectangle, section, .teacher, .contacts_text button, .teacher button, 
.teacher_modal, .teacher_modal .modal-body, .teacher_modal .row, .teacher_modal .modal-header, .teacher_modal .btn-primary, .teacher_modal .modal-footer,
.menu-space, .accesibility-A button, .navbar .dropdown-menu,
  .accessibleenvironment p, .table_style .table_header th, .table_style .table_body td, .table_style .table_header_first td) {
  color: black !important;
  background-color: white;
}
.contrast-white-black,
.contrast-black-white:is(.contacts_text button, .teacher button, .card-rectangle, .teacher, .menu-rectangle, .menu-space .btn, .teacher_modal .btn-primary, .menu-accesibility, .logo *) {
  border-color: black;
  fill: black;
}
.contrast-white-black .h2_text::after,
.contrast-white-black .h4_text::after {
  border-bottom: 1px solid black;
}
.contrast-white-black :is(.table_style .table_header th, .table_style .table_body td, .table_style .table_header_first td) {
  border-color: black;
}
.contrast-white-black .navbar-collapse {
  border-top: 1px solid black;
}
.contrast-white-black .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgb(0, 0, 0)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.contrast-white-black .menu_eye {
  color: black;
}

.contrast-black-white,
.contrast-black-white :is(h1, h2, h3, h4, h5, h6, p, a, span, .main_container, .card-rectangle, section, .teacher, .contacts_text button, .teacher button, 
.teacher_modal, .teacher_modal .modal-body, .teacher_modal .row, .teacher_modal .modal-header, .teacher_modal .btn-primary, .teacher_modal .modal-footer,
.menu-space, .accesibility-A button, .navbar .dropdown-menu,
  .accessibleenvironment p, .table_style .table_header th, .table_style .table_body td, .table_style .table_header_first td) {
  color: white !important;
  background-color: black;
}
.contrast-black-white,
.contrast-black-white :is(.contacts_text button, .teacher button, .card-rectangle, .teacher, .menu-rectangle, .menu-space .btn, .teacher_modal .btn-primary, .menu-accesibility, .logo *) {
  border-color: white;
  fill: white;
}
.contrast-black-white .h2_text::after,
.contrast-black-white .h4_text::after {
  border-bottom: 1px solid white;
}
.contrast-black-white :is(.table_style .table_header th, .table_style .table_body td, .table_style .table_header_first td) {
  border-color: white;
}
.contrast-black-white .navbar-collapse {
  border-top: 1px solid white;
}
.contrast-black-white .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgb(255, 255, 255)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.contrast-black-white .menu_eye {
  color: white;
}

.contrast-blue,
.contrast-blue :is(h1, h2, h3, h4, h5, h6, p, a, span, .main_container, .card-rectangle, section, .teacher, .contacts_text button, .teacher button, 
.teacher_modal, .teacher_modal .modal-body, .teacher_modal .row, .teacher_modal .modal-header, .teacher_modal .btn-primary, .teacher_modal .modal-footer,
.menu-space, .accesibility-A button, .navbar .dropdown-menu,
  .accessibleenvironment p, .table_style .table_header th, .table_style .table_body td, .table_style .table_header_first td) {
  color: #195183 !important;
  background-color: #9dd1ff;
}
.contrast-blue,
.contrast-blue :is(.contacts_text button, .teacher button, .card-rectangle, .teacher, .menu-rectangle, .menu-space .btn, .teacher_modal .btn-primary, .menu-accesibility, .logo *) {
  border-color: #195183;
  fill: #195183;
}
.contrast-blue .h2_text::after,
.contrast-blue .h4_text::after {
  border-bottom: 1px solid #195183;
}
.contrast-blue :is(.table_style .table_header th, .table_style .table_body td, .table_style .table_header_first td) {
  border-color: #195183;
}
.contrast-blue .navbar-collapse {
  border-top: 1px solid #195183;
}
.contrast-blue .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgb(25, 81, 131)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.contrast-blue .menu_eye {
  color: #195183;
}

.contrast-beige-brown,
.contrast-beige-brown :is(h1, h2, h3, h4, h5, h6, p, a, span, .main_container, .card-rectangle, section, .teacher, .contacts_text button, .teacher button, 
.teacher_modal, .teacher_modal .modal-body, .teacher_modal .row, .teacher_modal .modal-header, .teacher_modal .btn-primary, .teacher_modal .modal-footer,
.menu-space, .accesibility-A button, .navbar .dropdown-menu,
  .accessibleenvironment p, .table_style .table_header th, .table_style .table_body td, .table_style .table_header_first td) {
  color: #7c3c00 !important;
  background-color: #f7f3d6;
}
.contrast-beige-brown,
.contrast-beige-brown :is(.contacts_text button, .teacher button, .card-rectangle, .teacher, .menu-rectangle, .menu-space .btn, .teacher_modal .btn-primary, .menu-accesibility, .logo *) {
  border-color: #7c3c00;
  fill: #7c3c00;
}
.contrast-beige-brown .h2_text::after,
.contrast-beige-brown .h4_text::after {
  border-bottom: 1px solid #7c3c00;
}
.contrast-beige-brown :is(.table_style .table_header th, .table_style .table_body td, .table_style .table_header_first td) {
  border-color: #7c3c00;
}
.contrast-beige-brown .navbar-collapse {
  border-top: 1px solid #7c3c00;
}
.contrast-beige-brown .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgb(124, 60, 0)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.contrast-beige-brown .menu_eye {
  color: #7c3c00;
}


.contrast-brown-yellow-green,
.contrast-brown-yellow-green :is(h1, h2, h3, h4, h5, h6, p, a, span, .main_container, .card-rectangle, section, .teacher, .contacts_text button, .teacher button,
.teacher_modal, .teacher_modal .modal-body, .teacher_modal .row, .teacher_modal .modal-header, .teacher_modal .btn-primary, .teacher_modal .modal-footer,
.menu-space, .accesibility-A button, .navbar .dropdown-menu,
  .accessibleenvironment p, .table_style .table_header th, .table_style .table_body td, .table_style .table_header_first td) {
  color: #a9e44d !important;
  background-color: #3b2716;
}
.contrast-brown-yellow-green,
.contrast-brown-yellow-green :is(.contacts_text button, .teacher button, .card-rectangle, .teacher,
.menu-rectangle, .menu-space .btn, .teacher_modal .btn-primary, .menu-accesibility, .logo *) {
  border-color: #a9e44d;
  fill: #a9e44d
}
.contrast-brown-yellow-green .h2_text::after,
.contrast-brown-yellow-green .h4_text::after {
  border-bottom: 1px solid #a9e44d;
}
.contrast-brown-yellow-green :is(.table_style .table_header th, .table_style .table_body td, .table_style .table_header_first td) {
  border-color: #a9e44d;
}
.contrast-brown-yellow-green .navbar-collapse {
  border-top: 1px solid #a9e44d;
}
.contrast-brown-yellow-green .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgb(169, 228, 77)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.contrast-brown-yellow-green .menu_eye {
  color: #a9e44d;
}
/* */
.main_container {
  margin: auto 200px;
  padding-top: 60px;
  padding-bottom: 60px;
  max-width: 100%;
}

.main_row {
  max-width: 100%;
  display: flex;
  flex-direction: row;
}
/**/
.lead {
  background: var(--secondary-2);
  position: relative;
  /*padding-bottom: 150px;*/
  padding-bottom: 7em;
}

.lead-header {
  background: var(--secondary-4);
  position: relative;
  /*padding-bottom: 150px;
  padding-bottom: 7em;*/
}
.lead-header .main_container {
  display: flex;
  flex-direction: row;
  padding-top: 40px;
  padding-bottom: 40px;
}
.lead-header h1 {
  position: relative;
  padding-top: 100px;
  font-weight: 700;
  /*font-size: 36px;*/
  font-size: 1.75em;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: var(--main-1);
  text-align: left;
  text-transform: uppercase;
  z-index: 2;
}
.lead-header p {
  color: var(--text-2);
  font-size: 1.25em;
  margin-bottom: 0;
}
.lead-header img {
  width: 200px;
  z-index: 1;
  position: absolute;
  /*left: calc(40%);*/
  left: calc(100% - 200px - 200px);
  height: 200px;
  margin-right: 200px;
  margin-top: 200px;
  bottom: 0;
  /*margin-bottom: 40px;*/
}


.lead .main_container {
  display: flex;
  flex-direction: row;
  padding-top: 40px;
  padding-bottom: 40px;
}

.lead h1 {
  font-family: "Montserrat", sans-serif;
  padding-top: 145px;
  padding-bottom: 45px;
  font-weight: 700;
  /*font-size: 36px;*/
  font-size: 1.75em;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: var(--main-1);
  text-align: center;
  text-transform: uppercase;
  z-index: 2;
}
.lead img {
  /*max-width: 50%;*/
  z-index: 1;
  position: absolute;
  /*left: calc(40%);*/
  left: calc(35%);
  height: -webkit-fill-available;
  margin-bottom: 40px;
}
/* */
.target .main_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 90px;
  position: relative;
}

.target .main_container_target_accesibility{
  display: flex;
  flex-direction: column;
  margin: auto 200px;
  max-width: 100%;
}

.target .main_container .target_about {
  max-width: 50%;
}

.target .main_container_target_accesibility .target_about {
  max-width: 100%;
}
.target p {
  font-family: var(--font-family);
  font-weight: 400;
  /*font-size: 20px;*/
  font-size: 1.25em;
  color: var(--text-1);
}

.target-circle {
  margin-left: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}

.circle-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.circle {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  color: white;
}

.circle-large {
  width: 346px;
  height: 346px;
  background-color: var(--secondary-4);
  border: 1px solid var(--secondary-3);
  color: #333;
  z-index: 2;
}

.circle-small {
  width: 199px;
  height: 199px;
  background-color: #2b3f73;
  position: absolute;
  bottom: -80px;
  left: -80px;
  z-index: 1;
}

.circle-large p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1em;
  text-align: center;
  color: var(--text-2);
  margin: 0 10%;
}

.circle-small p {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 1.4em;
  color: var(--main-2);
  text-align: left;
}

/* */
.card-section {
  background: var(--main-2);
  /*padding-bottom: 40px;*/
}

.card-section-home {
  background: var(--secondary-4);
}

.card-section h1,
.gallery h1,
.target h1,
.section_blue h1 {
  color: var(--main-1);
}

.card-section h3,
.section_light h3 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.25em;
  color: var(--text-1);
}

.card-section h3 {
  padding-top: 20px;
}
/* */
.gallery {
  padding-bottom: 40px;
}

/* */
.home-contacts-row {
  justify-content: space-between;
}

.home-contacts-section {
  background: var(--main-1);
  margin-bottom: 25px;
}

div .card-rectangle.right-rectangle.col {
  margin-left: 15px;
}

div .card-rectangle.left-rectangle.col {
  margin-right: 15px;
}

.target h1,
.home-contacts-section h1,
.card-section h1,
.gallery h1,
.section_blue h1 {
  padding-top: 45px;
  padding-bottom: 45px;
  font-family: var(--second-family);
  font-weight: 700;
  /*font-size: 36px;*/
  font-size: 2.25em;
  line-height: 140%;
  letter-spacing: 0.02em;
}

.section_blue h1 {
  padding-top: 70px;
  padding-bottom: 30px;
  text-transform: uppercase;
}

.location {
  color: var(--text-2);
  font-size: 1.25em;
  margin-top: 2.5rem;
}

.main_container_with_h1 {
  padding-top: 0;
  padding-bottom: 0;
}

.main_container_with_h1_icon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.home-contacts-section h1 {
  color: var(--main-2);
}

.home-contacts-section h2,
.card-section h2,
.card-section a.h2-a,
.course_table h2,
.section_light h2,
.teacher h2 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 1.4em;
  color: var(--main-1);
  text-decoration: none;
}

.section_light h3 {
  /*margin: calc(0.75rem + .5vw) 0 calc(1rem + .5vw) 0;
  color: var(--main-1);
  font-size: calc(1.2em + .3vw);*/
  /*font-family: var(--font-family);
  font-weight: 600;
  font-size: 1.3em;
  color: var(--main-1);*/
  margin: 1rem 0 0.75rem 0;
}

.section_light h4 {
  /*margin: calc(0.5rem + .5vw) 0 calc(1rem + .5vw) 0;
  color: var(--main-1);
  position: relative;
  font-size: calc(1.15em);*/
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 1.25em;
  color: var(--main-1);
  margin: 1rem 0 0.5rem 0;
}

.section_light span {
  color: var(--text-2);
}

.home-contacts-section p,
.card-section p {
  padding-top: 20px;
}

.home-contacts-section .map,
.card-section .save_doc {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1em;
  color: var(--text-3);
  cursor: pointer;
  text-decoration: none;
}

.card-rectangle {
  display: flex;
  flex-direction: column;
  padding: 40px;
  border: 0.50px solid var(--secondary-3);
  border-radius: 20px;
  max-width: 100%;
  height: 100%;
  /*background: var(--main-2);*/
}

.blue-rectangle {
  background: var(--secondary-4, #F7F8FB);
}

.white-rectangle {
  background: var(--main-2);
}

.card-rectangle p a,
.card-rectangle p,
.section-light p a {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1em;
  color: var(--text-2);
  /*text-decoration: none;*/
  line-height: 1.375em;
  margin-bottom: 0;
}

.card-rectangle-with-img {
  display: flex;
  flex-direction: row;
}

.card-rectangle-with-img-text p {
  padding-top: 0px;
  font-size: 1.25em;
}

.card-rectangle-with-img img {
  width: 480px;
  margin-right: 40px;
}

.text-decoration-none {
  text-decoration: none;
}

.gallery .carousel {
  display: flex;
  justify-content: center;

}

.gallery .carousel-inner {
  width: 70%;
}

.gallery .carousel-item img {
  border-radius: 10px;
}

.gallery .carousel-control-prev-icon {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23283B70%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e');
}

.gallery .carousel-control-next-icon {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23283B70%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
}


footer {
  /*padding-bottom: 75px;*/
  background: var(--main-1);
  text-align: left;
  display: flex;
  flex-direction: column;
  /*border-top: 3px solid #01487E;*/
  width: 100%;
}

/*Руководство*/
.section_blue {
  background: var(--secondary-4);
}

.section_light {
  border: 0.50px solid var(--secondary-3);
  border-left: none;
  border-right: none;
}

.section_light h2 {
  /*padding-top: 20px;*/
  margin-bottom: 30px;
}

.section_light .h2_only {
  margin-bottom: 0;
}

.section_light p, ul li {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.25em;
  color: var(--text-2);
  margin-bottom: 0;
  line-height: 1,375em;
}

.teachers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}
/* < новое */
.loader {
  width: 148px;
  height: 148px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40vh;
  margin-left: 40vw;
  background: linear-gradient(0deg, var(--text-2) 5%, var(--text-3) 100%);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 124px;
  height: 124px;
  border-radius: 50%;
  background: #fff;
}
@keyframes rotation {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg)}
} 


.teachers-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.teachers-row.two {
  width: 66%;
}

.teacher {
  width: calc(100% / 3 - 20px);
  border: 1px solid var(--secondary-3);
  border-radius: 20px;
  background: var(--main-2);
  margin-top: 20px;
}

.one {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.teacher.one img {
  width: 33%;
  height: auto;
  border-radius: 19px 0 0 19px;
}

.teacher.two {
  width: calc(100% / 2 - 20px);
}

/* новое > */
.ceo {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.teacher img {
  width: 100%;
  height: auto;
  border-radius: 19px 19px 0 0;
}

.ceo img {
  width: 33%;
  height: auto;
  border-radius: 19px 0 0 19px;
}

.teacher_text {
  padding: 20px;
  padding-top: 0;
}

.teacher h2 {
  /*max-width: 90%;*/
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 0;
}

div.teacher p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1em;
  color: var(--text-2);
}

.rectangle-card-grid {
  display: flex;
  flex-direction: column;
}

/* Rectangle Card Grid */
.row-rectangle-card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  margin-bottom: 30px;
}

.col-half {
  grid-column: span 1;
}

.col-full {
  grid-column: span 2;
}

.col-full-bottom {
  margin-bottom: 25px;
}

.row-rectangle-card-grid-accesibility {
  grid-template-columns: 1fr;
  margin-top: 25px;
}

.row-rectangle-card-grid-accesibility div.card-rectangle.blue-rectangle.right-rectangle.col {
  margin-left: 0;
  margin-top: 25px;
}

.row-rectangle-card-grid-accesibility div.card-rectangle.blue-rectangle.left-rectangle.col  {
  margin-right: 0;
}

.conference-image {
  width: 100%;
}

@media screen and (min-width: 1440px) {
  .circle-large {
    width: 420px;
    height: 420px;
  }

  .circle-small {
    width: 240px;
    height: 240px;
    bottom: -45px;
    left: -130px;
    padding-right: 50px;
  }

  .circle-small p {
    font-size: 1.5em !important;
    font-weight: 600;
  }
  .circle-large p {
      font-weight: 400;
      font-size: 1em;
  }
}

@media screen and (max-width: 1440px) {
  .lead-header img {
    left: calc(100% - 200px - 40px);
  }
  .main_container {
    margin: auto 40px;
  }
  
  .main_row {
    flex-direction: column;
  }

  .menu-space .main_row,
  .home-contacts-row {
    flex-direction: row;
  }

  .menu-space .main_row {
    justify-content: space-between;
    align-items: baseline;
  }
  button.navbar-toggler.collapsed,
  button.navbar-toggler {
    border: none;

  }

  div .card-rectangle.right-rectangle.col {
    margin-left: 0;
    margin-top: 25px;
  }
  
  div .card-rectangle.left-rectangle.col {
    margin-right: 0;
  }

  .card-rectangle-with-img {
    flex-direction: column;
  }

  .card-rectangle-with-img img {
    width: -webkit-fill-available;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .target p {
    font-weight: 300;
    font-size: 1.125em;
  }

  .lead h1 {
    font-size: 1.25em;
  }

  .lead img {
    /*max-width: 70%;*/
    z-index: 1;
    position: absolute;
    /*left: calc(30%);*/
    left: calc(25%);
  }

  .target h1,
  .home-contacts-section h1,
  .card-section h1,
  .gallery h1,
  .section_blue h1,
  .guide_white h1 {
    font-size: 2em;

  }

  .target-circle {
    margin-left: 130px;}

  .circle-large {
    width: 308px;
    height: 308px;
  }

  .circle-small {
    bottom: -45px;
    left: -130px;
  }

  .circle-small p {
    font-size: 1.5em !important;
    font-weight: 600;
  }
  .circle-large p {
      font-weight: 400;
      font-size: 1em;
  }

  .row-rectangle-card-grid {
    grid-template-columns: 1fr;
    margin-top: 25px;
  }

  .row-rectangle-card-grid-first {
    margin-top: 0;
  }
}

@media screen and (max-width: 1200px) {
  .menu-space .main_container {
    display: flex;
    flex-direction: column;
  }
  .menu-space .main_row {
    flex-direction: column;
  }

  .brand_toggle {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .navbar-collapse {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .navbar .nav-link {
    text-align: end;
  }

  .navbar-collapse {
    border-top: 1px solid var(--main-1);
  }

  .navbar .dropdown-item {
    text-align: end;
  }

  .navbar-toggler-icon {
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(40, 59, 112, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .lead img {
    left: calc(20%);
  }

}

@media screen and (max-width: 1050px) {
  .lead img {
    left: calc(15%);
  }
}

@media screen and (max-width: 992px) {
  .gallery .carousel-inner {
    width: 100%;
  }

  .gallery .carousel-control-prev-icon {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e');
  }
  
  .gallery .carousel-control-next-icon {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
  }

  .teachers-row.one {
    width: 100%;
  }

  .teachers-row.two {
    width: 100%;
  }

  .teacher {
    width: calc(100% / 2 - 20px);
  }

  .teacher.one {
    width: 100%;
  }

  .teacher.one img {
    width: 50%;
  }

  .ceo {
    width: 100%;
  }

  .ceo {
  display: flex;
  flex-direction: row;
  width: 100%;
  }

  .ceo img {
    width: 100%;
  }

  .lead {
    padding-bottom: 4em;
  }

  .lead h1 {
    padding-top: 45px;
  }

  .lead img {
    left: calc(40%);
    width: -webkit-fill-available;
    height: auto;
  }
}

@media screen and (max-width: 900px) {
.lead {
  padding-bottom: 2em;
}
}

@media screen and (max-width: 768px) {
  .lead-header .main_container {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .lead-header h1 {
    padding-top: 40px;
  }

  .lead-header img {
    width: 145px;
    left: calc(100% - 145px - 10px);
    height: 145px;
  }

  .main_container {
    margin: auto 10px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  
  .lead .main_container {
    flex-direction: column;
  }

  .lead h1 {
    padding-top: 0;
    font-weight: 700;
    font-size: 1em;
    z-index: none;
  }

  .target h1,
  .home-contacts-section h1,
  .card-section h1,
  .gallery h1,
  .section_blue h1,
  .guide_white h1 {
    font-size: 1.5em;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .section_blue h1 {
    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: 0;
  }

  .card-rectangle {
    padding: 20px;
  }

  .lead img {
    max-width: 100%;
    z-index: 1;
    position: relative;
    left: 0
  }

  .lead {
    padding-bottom: 0;
  }

  .target .main_container {
    flex-direction: column;
    padding-bottom: 3.5em;
  }

  .target .main_container .target_about {
    max-width: 100%;
  }

  .target-circle {
    margin-left: 0;
  }

  .circle {
    justify-content: start;
  }

  .circle-large {
    width: 274px;
    height: 274px;
  }

  .circle-small {
    width: 168px;
    height: 168px;
    bottom: -40px;
    left: -90px;
  }

  .circle-small p {
    font-size: 1.25em !important;
    font-weight: 600;
    padding-top: 20px;
    padding-left: 10px;
  }

  .circle-large p {
      font-size: .95em;
      font-weight: 400;
  }

  /*.gallery img {
    max-width: 75%;
  }*/

  /*footer {
    padding-bottom: 40px;
  }*/

  .section_blue .main_container {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .main_container.accessibleenvironment {
    flex-direction: column;
  }

  .main_container.accessibleenvironment img {
    max-width: 100%;
  }

  .main_container.equipment {
    flex-direction: column;
  }

  .main_container.equipment img {
    max-width: 100%;
  }

}

@media screen and (max-width: 576px) {
  /*.target-circle {
    justify-content: flex-end;
  }*/
  .main_container {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .teachers {
    flex-direction: column;
    align-items: center
  }

.teachers-row.one {
  justify-content: center;
}

  .teacher {
    width: 70%;
  }

  .teacher img {
    width: 100%;
  }

  .teacher.one {
    width: 70%;
    flex-direction: column;
}

  .teacher.one img {
    width: 100%;
    border-radius: 19px 19px 0 0;
  }

  .ceo {
    flex-direction: column;
  }

  .ceo img {
    border-radius: 19px 19px 0 0;
  }

}

@media screen and (max-width: 440px) {

  .lead img {
    margin-bottom: 10px;
  }

  .circle-large {
    width: 200px;
    height: 200px;
  }

  .circle-small {
    width: 100px;
    height: 100px;
    bottom: -35px;
    left: -40px;
  }

  .circle-small p {
    font-size: .7em !important;
    font-weight: 600;
  }

  .circle-large p {
      font-size: .6em;
      font-weight: 400;
  }
}
/*footer .row {
  margin-right: 0;
}

footer .col {
  padding-right: 0;
}

footer p {
  margin: 10px 0 10px calc(1.5rem + .5vw);
  color: white;
}

footer p a {
  text-decoration: none;
  color: white;
} */



/*Старый дизайн*/
/* ссылки */
.a_link {
  text-decoration: none;
  color: inherit;
}

.card-rectangle p a.link {
  color: var(--text-3);
}

/* главная */
.main_text {
  color: #fff;
  /* background-image: url('../public/files/main.png'); */
  width: 100%;
  /* height: calc(5vw); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0% 10% 20% 10%;
  text-align: center;
}
.main-h1 {
  font-size: calc(2em + .5vw);
  color:#01487E;
}
.target .row {
  align-items: center;
}
/*.target h1 {
  margin-right: calc(2em + .5vw);
  color:#01487E;
  line-height: 2rem;
}*/
/*.target p {
  font-size: calc(1em + .5vw);
}*/
.license .row {
  align-items: center;
}
.main_img img {
  max-width: 100%;
  margin: 1rem;
}
.license h1 {
  margin-right: calc(2rem + .5vw);
  color:#01487E;
}
.license p {
  font-size: calc(0.7em + .5vw);
  line-height: calc(1.5em + .5vw);
}
.h1_programm {
  margin: calc(2rem + .5vw) 0 calc(.3rem + .5vw) 0;
  color:#01487E;
}
.programm {
  border: 3px solid #01487E;
  border-radius: 20px;
  padding: calc(0.5rem + .2vw);
  margin-bottom: calc(2rem + .5vw);
}
.programm h4 {
  color:#01487E;
  font-weight: 400;
  font-size: calc(1.3em + .5vw);
}
.programm p {
  font-size: calc(1em + .5vw);
  line-height: calc(1em + .5vw);
}
.contacts_text h1 {
  color:#01487E;
  margin-bottom: calc(2rem + .5vw)
}
.contacts_text p {
  font-size: calc(1em + .5vw);
}
.contacts_text button {
  margin: calc(2rem + .5vw) 0;
  background-color:#01487E;
  color: #fff;
  font-size: calc(1em + .5vw);
  border-color: #01487E;
  border-radius: 20px;
  padding: 10px 20px;
}
.contacts_text button a {
  text-decoration: none;
  color: #fff;
}
.map iframe,
.map div {
  border-radius: 20px;
  height: calc(30rem);
  width: 100%;
}
.map div {
  background-color: #F5F5F5;  
}
/* преподаватели */
.teacher button {
  background-color:#01487E;
  color: #fff;
  border-color: #01487E;  
  font-size: calc(1em);
}
.teacher_modal .row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.teacher_modal .modal-header {
  color: #01487E;
}
.teacher_modal .modal-title {
  font-size: calc(1.3em + .5vw);
}
.teacher_modal .btn-primary {
  background-color:#01487E;
  color: #fff;
  border-color: #01487E; 
  font-size: calc(1em);
}
/* вакантные места */
.placesadmission {
  display: flex;
  justify-content: center;
  align-items: center;
}

.placesadmission img {
  width: 100%;
}

/* документы */
.icon_div {
  display: flex; 
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 0 0 1rem 0;
  line-height: 1rem;
}

.icon_doc {
  /* color: #01487E; */
  font-size: 2rem;
  min-width: 2rem;
}

.default_icon_color {
  color: var(--main-1);
}

.icon_text {
  margin-left: 0.5rem;
  /* font-size: calc(0.7rem + .5vw); */
  /*line-height: calc(0.7em + .5vw);*/
  line-height: 1.25em;
}

/* руководство */
/*.guide {
  align-items: center;
  margin-bottom: calc(2rem + .5vw);
}
.guide p, .teacher p {
  font-size: calc(1.2em + .3vw);
}
.guide h3, .teacher h3 {
  font-size: calc(1.375em + .9vw);
}
.teacher {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem;
}
.teacher img {
  margin-right: 1rem;
}*/

/* образование таблица */
.course_table {
  padding-top: 0;
  padding-bottom: 0;
}

.table_style {
  border: 1px solid var(--secondary-3);
  margin-top: 30px;
}

.table_style .table_header_first td {
  color: var(--text-2);
  font-weight: 300;
  font-size: 1.125em;
  text-align: center;
  border: 1px solid var(--secondary-3);
}

.table_style .table_header th {
  color: var(--text-2);
  font-weight: 400;
  font-size: 1em;
  text-align: center;
  border: 1px solid var(--secondary-3);
}

.table_style .table_header .table_col_name,
.table_body .table_col_name {
  text-align: left;
}

.table_style thead {
  border-color: #01487E00;
}

.table_body tr {
  border-color: #01487E00;
}

.table_body tr td {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1em;
  color: var(--text-2);
  text-align: center;
  border: 1px solid var(--secondary-3);
}

.equipment {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.equipment img {
  max-width: 32%;
  margin: 1em 0;
  /* margin: 1rem; */
}

/* доступная среда */
.accessibleenvironment {
  align-items: center;
  display: flex;
  flex-direction: row;
}

/*.accessibleenvironment p {
  font-size: calc(1.2em + .3vw);
  margin-right: calc(2vw);
  color: #313131;
}*/

.accessibleenvironment img {
  max-width: 50%;
}

/* международное сотрудничество */
/*.internationalcooperation {
  align-items: center;
}
.internationalcooperation > * {
  margin-bottom: calc(1.5rem + .5vw);
}*/
.internationalcooperation-img {
  max-width: 100%;
}


@media (max-width: 1200px) {
  /*.teacher {
    flex-direction: column;
    text-align: center;
  }
  .teacher_modal .row {
    text-align: center;
  }
  .guide {
    text-align: center;
  }*/
}