.root {
    display: inline-block;
    gap: 10px;
    padding: 0;
    margin: 0;

    li {
        display: inline;
        position: relative;
        padding-right: 26px;
    }

    li:last-child {
        pointer-events: none;

        a {
            font-weight: 600;
        }
    }

    li:not(:last-child)::after {
        display: block;
        position: absolute;
        content: '/';
        left: calc(100% - 16px);
        bottom: 0;
    }

    a {
        display: inline;
        padding: 0;
        color: rgb(93, 93, 93);
        font-size: 20px;
        line-height: 28px;
        text-align: left;
        text-decoration: none;
    }
}
